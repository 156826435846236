//import mapStyle from './style';

async function initMap() {
	let iw = window.innerWidth;
	let z = 3.82;
	if (iw < 1440) {
	 	z = 3.4;
	}
	if (iw < 900) {
	 	z = 2.9;
	}
	if (iw < 600) {
	 	z = 2.8;
	}

	const {Map} = await google.maps.importLibrary("maps");
	const {AdvancedMarkerElement, PinElement} = await google.maps.importLibrary("marker");

	const pinSettings = {
		background: "#3b892c",
		borderColor: "#ffffff",
		glyphColor: "#ffffff",
	};

	const center = window.mapCoord ? window.mapCoord : {lat: 1, lng: 17.2812};

	const map = new Map(document.getElementById("map"), {
		zoom: z,
		center: center,
		disableDefaultUI: true,
		zoomControl: true,
		fullscreenControl: false,
		//styles: mapStyle,
		mapId: "1ee7815b144d52df",
	});

	window.mapMarkers.forEach((item) => {
		const pin = new PinElement(pinSettings);

		const marker = new AdvancedMarkerElement({
			map: map,
			position: item.position,
			title: item.title,
			content: pin.element,
		});

		if (item.url) {
			marker.addListener('click', function({domEvent, latLng}) {
				window.location.href = item.url;
			});
		}
	});
}

window.initMap = initMap;